.animate-pulse-logo {
  animation: pulse 1.6s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1.8);
    opacity: 1;
  }

  50% {
    transform: scale(1.9);
    opacity: 0.8;
  }

  100% {
    transform: scale(1.8);
    opacity: 1;
  }
}

.animate-pulse {
  animation: pulse 1.6s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1.0);
    opacity: 1;
  }

  50% {
    transform: scale(1.05);
    opacity: 0.8;
  }

  100% {
    transform: scale(1.0);
    opacity: 1;
  }
}


/* For viewport widths greater than 450px, bottom will be 17%. */
.swiper-pagination {
  bottom: 17% !important;
}

/* For viewport widths between 381px and 450px, bottom will be 22%. */
@media (max-width: 450px) {
  .swiper-pagination {
    bottom: 21% !important;
  }
}

/* For viewport widths 380px or less, bottom will be 20%. */
@media (max-width: 380px) {
  .swiper-pagination {
    bottom: 22% !important;
  }
}